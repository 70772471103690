<template>
  <div class="overall">
    <!-- <div class="main-Title bgff">
      <h2>Overall</h2>
    </div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" size="mini" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="Source" class="mb5">
                <group-select
                  v-model="filter.sourceIds"
                  :data-source="sourcesList"
                  multiple
                  collapse-tags
                  :loading="sourceLoading"
                  clearable
                  reserve-keyword
                  filterable
                  placeholder="请选择"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="status" class="mb5">
                <el-select v-model="filter.status" class="w100">
                  <el-option
                    v-for="item in sourceStatus"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="PayoutType" class="mb5">
                <el-select v-model="filter.payoutType" clearable class="w100">
                  <el-option
                    v-for="item in payoutTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="Source Name" label-width="100px" class="mb5">
                <el-input
                  v-model="filter.sourceName"
                  placeholder="chestnut"
                  class="w100"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="BD" class="mb5">
                <group-select
                  v-model="filter.businessList"
                  :data-source="busineies"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select BD"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="BD Group" class="mb5">
                <el-select v-model="filter.businessType" clearable class="w100">
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="AM" class="mb5">
                <group-select
                  v-model="filter.accountList"
                  :data-source="accounts"
                  :multiple="true"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select AM"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="AM Group" class="mb5">
                <el-select v-model="filter.accountType" clearable class="w100">
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="4" align="right">
              <el-form-item label=" " label-width="0px" class="mb5">
                <el-button type="primary" @click="searchListClick(1)">Search</el-button>
                <el-button type="primary" icon="el-icon-plus" @click="createClick"
                  >Create</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          border
          stripe
          highlight-current-row
          :data="dataList"
          style="width: 100%"
          size="mini"
        >
          <el-table-column label="Id" prop="id" align="center" fixed="left">
            <template slot-scope="scope">
              <span @click="detailClick(scope.row)" class="cor337ab7">{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Name" prop="company" align="center" min-width="110" fixed="left">
            <template slot-scope="scope">
              <span @click="detailClick(scope.row)" class="cor337ab7">{{ scope.row.company }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Email"
            prop="email"
            align="center"
            show-overflow-tooltip
            min-width="100"
          >
            <template slot-scope="scope">
              {{ formatEmail(scope.row.email) }}
            </template>
          </el-table-column>
          <el-table-column
            label="SignName"
            prop="signName"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column label="Status" prop="enable" align="center"></el-table-column>
          <el-table-column
            label="Whitelist Limited"
            prop="whitelistLimited"
            align="center"
            width="80"
          >
            <template slot="header">
              Whitelist<br />
              Limited
            </template>
          </el-table-column>
          <el-table-column label="Weightiness" prop="weightiness" align="center" width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.weightiness <= 5">只测一次单</span>
              <span v-else-if="scope.row.weightiness >= 6">正常测单</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Description"
            prop="description"
            align="center"
            min-width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="timeZone" align="center" width="50">
            <template slot="header">
              Time<br />
              Zone
            </template>
          </el-table-column>
          <el-table-column label="Traffic Type" prop="trafficType" align="center" min-width="60">
            <template slot="header">
              Traffic<br />
              Type
            </template>
          </el-table-column>
          <el-table-column
            label="Traffic Tag"
            prop="trafficTag"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column label="SyncUrl" min-width="140" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <a style="color: #606266" target="_blank" :href="scope.row.syncUrl"
                ><span>{{ scope.row.syncUrl }}</span></a
              >
            </template>
          </el-table-column>
          <el-table-column prop="payoutType" min-width="65" align="center">
            <template slot="header">
              Payout<br />
              Type
            </template>
          </el-table-column>
          <el-table-column label="MaxPullOffer" prop="pullOfferMaxRecord" width="70" align="center">
            <template slot="header">
              MaxPull<br />
              Offer
            </template>
          </el-table-column>
          <!-- <el-table-column prop="createBy" width="70" align="center">
            <template slot="header">
              Account<br />
              Name
            </template>
          </el-table-column> -->
          <el-table-column label="PM" prop="manager" width="80"></el-table-column>
          <el-table-column label="BD Name" prop="business" align="center" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.business }}
              <!--              <i class="el-icon-edit" @click="handleBusiness(scope.row)"></i>-->
            </template>
          </el-table-column>
          <el-table-column label="BD Group" prop="business" align="center" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.businessType }}
              <i class="el-icon-edit" @click="handleBusinessType(scope.row)"></i>
            </template>
          </el-table-column>
          <el-table-column label="AM" prop="accountManage" align="center" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.accountManage }}
              <i class="el-icon-edit" @click="handleAccountManager(scope.row)"></i>
            </template>
          </el-table-column>
          <el-table-column label="AM Group" prop="accountType" align="center" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.accountType }}
              <i class="el-icon-edit" @click="handleAccountType(scope.row)"></i>
            </template>
          </el-table-column>
          <el-table-column
            label="Time"
            prop="createTime"
            align="center"
            width="85"
          ></el-table-column>
          <!-- show-overflow-tooltip -->
          <el-table-column label="Action" align="center" min-width="120" fixed="right">
            <template slot-scope="scope">
              <el-link type="primary" @click="updateClick(scope.row)" :underline="false"
                >update</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="align-c">
          <pagination
            class="block pagePanel"
            :pageTotal="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :pageSize="pageSize"
            :currentPage="currentPage"
          ></pagination>
        </div>
      </el-card>
    </div>

    <!-- 添加模块框 -->
    <el-dialog
      :title="dialogType === 'edit' ? 'Source Update' : 'Source Create'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="closemoda"
      append-to-body
    >
      <el-form
        v-if="dialogType === 'new'"
        label-position="right"
        label-width="110px"
        :model="source"
        :rules="rules"
        ref="source"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="SourceName" prop="company">
              <el-input v-model="source.company" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Weightiness" prop="weightiness">
              <el-input v-model="source.weightiness" placeholder="1~10"></el-input>
              <el-tag type="warning">权重低于等于5每日只测一次单</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Description" prop="description">
              <el-input v-model="source.description" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="TimeZone">
              <el-input v-model="source.timeZone" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Traffic Type">
              <el-select v-model="source.trafficType" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in trafficTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Traffic Tag:" prop="trafficTag">
              <el-select
                v-model="source.trafficTag"
                multiple
                collapse-tags
                clearable
                filterable
                placeholder="Please select"
                class="w100"
              >
                <el-option
                  v-for="item in tagOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Payout Type">
              <el-select v-model="source.payoutType" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in payoutType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="PM" prop="manager">
              <el-select
                v-model="source.manager"
                filterable
                placeholder="Please select"
                class="w100"
              >
                <el-option
                  v-for="item in option.manager"
                  :key="item.username"
                  :label="item.username"
                  :value="item.username"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="BD Name" prop="business">
              <!--              <el-input v-model="source.business" placeholder="Please add BD Name"></el-input>-->
              <el-select
                v-model="source.business"
                filterable
                placeholder="Please select BD Name"
                class="w100"
              >
                <el-option
                  v-for="item in option.manager"
                  :key="item.username"
                  :label="item.username"
                  :value="item.username"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="BD Group" prop="businessType">
              <el-select v-model="source.businessType" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in businessTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="AM" prop="accountManage">
              <!-- <el-input v-model="source.accountManage" placeholder></el-input> -->
              <el-select
                v-model="source.accountManage"
                filterable
                placeholder="Please select AM Name"
                class="w100"
              >
                <el-option
                  v-for="item in option.manager"
                  :key="item.username"
                  :label="item.username"
                  :value="item.username"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="AM Group" prop="accountType">
              <el-select v-model="source.accountType" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in businessTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Client Area" prop="clientArea">
              <el-select v-model="source.clientArea" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in businessTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14" v-for="(email, index) in source.emails" :key="email.key">
            <el-form-item
              :label="'email-' + index"
              :prop="'emails.' + index + '.value'"
              :rules="{
                required: true,
                message: 'email is null',
                trigger: 'blur',
              }"
            >
              <el-input v-model="email.value" style="width: 85%"></el-input>
              <el-button type="danger" size="mini" @click.prevent="removeDomain(email)"
                >删除</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" align="right">
            <el-button @click="dialogVisible = false" size="small">取 消</el-button>
            <el-button size="small" @click="addDomain('add')">新增email</el-button>
            <el-button type="primary" @click="submitClick('source')" size="small">确定</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!-- 编辑模块框 -->
      <el-form
        v-if="dialogType === 'edit'"
        label-position="right"
        ref="source"
        :model="source"
        label-width="110px"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Name" prop="company">
              <el-input v-model="source.company" placeholder="please add name..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Description" prop="description">
              <el-input v-model="source.description" placeholder="please add desc..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Status">
              <el-select v-model="source.enable" class="w100">
                <el-option
                  v-for="item in sourceUpdateStaatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Payout Type">
              <el-select v-model="source.payoutType" class="w100">
                <el-option
                  v-for="item in payoutType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Traffic Type">
              <el-select v-model="source.trafficType" class="w100">
                <el-option
                  v-for="item in trafficTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Traffic Tag:" prop="trafficTag">
              <el-select
                v-model="source.trafficTag"
                multiple
                collapse-tags
                clearable
                filterable
                placeholder="请选择"
                class="w100"
              >
                <el-option
                  v-for="item in tagOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="SignName">
              <el-input v-model="source.signName" placeholder="signName here"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="SyncUrl">
              <el-input v-model="source.syncUrl" placeholder="syncUrl here"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="TimeZone">
              <el-input
                v-model="source.timeZone"
                placeholder="eg: 8 or -8,(east tz[0~12],west tz[-1 ~ -12])"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Email">
              <el-input v-model="source.email" placeholder="please add email..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="PM" prop="manager">
              <el-select v-model="source.manager" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in option.manager"
                  :key="item.username"
                  :label="item.username"
                  :value="item.username"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="BD Name" prop="business">
              <el-select
                v-model="source.business"
                filterable
                placeholder="Please select BD Name"
                class="w100"
              >
                <el-option
                  v-for="item in option.manager"
                  :key="item.username"
                  :label="item.username"
                  :value="item.username"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="BD Group" prop="businessType">
              <el-select v-model="source.businessType" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in businessTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="AM" prop="accountManage">
              <!-- <el-input v-model="source.accountManage" placeholder></el-input> -->
              <el-select
                v-model="source.accountManage"
                filterable
                placeholder="Please select AM Name"
                class="w100"
              >
                <el-option
                  v-for="item in option.manager"
                  :key="item.username"
                  :label="item.username"
                  :value="item.username"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="AM Group" prop="accountType">
              <el-select v-model="source.accountType" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in businessTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="BD Coverage" prop="coverageDate">
              <el-date-picker
                v-model="source.coverageDate"
                type="month"
                value-format="yyyy-MM"
                placeholder="BD Coverage Date"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Client Area" prop="clientArea">
              <el-select v-model="source.clientArea" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in businessTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14" v-for="(email, index) in source.emails" :key="email.key">
            <el-form-item
              :label="'email-' + index"
              :prop="'emails.' + index + '.value'"
              :rules="{
                required: true,
                message: 'email is null',
                trigger: 'blur',
              }"
            >
              <el-input v-model="email.value" style="width: 85%"></el-input>
              <el-button type="danger" size="mini" @click.prevent="removeDomain(email)"
                >删除</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" align="right">
            <el-button @click="dialogVisible = false" size="small">取 消</el-button>
            <el-button size="small" @click="addDomain('add')">新增email</el-button>
            <el-button type="primary" @click="submitClick('source')" size="small">确定</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!--编辑商务模态框-->
    <el-dialog title="提示" :visible.sync="businessVisible" width="30%">
      <!--      <el-input v-model="business" placeholder="please add here"></el-input>-->
      <el-form :model="sourceBusiness">
        <el-form-item label="BD Name">
          <el-select v-model="business" filterable placeholder="Please select" class="w100">
            <el-option
              v-for="item in option.manager"
              :key="item.username"
              :label="item.username"
              :value="item.username"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="businessVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateSourceBusiness()">确 定</el-button>
      </span>
    </el-dialog>

    <!--编辑商务类型模态框-->
    <el-dialog title="提示" :visible.sync="businessTypeVisible" width="30%">
      <!--      <el-input v-model="businessType" placeholder="please add here"></el-input>-->
      <el-form :model="sourceBusinessType">
        <el-form-item label="BD Group">
          <el-select v-model="businessType" placeholder="Please select" class="w100">
            <el-option
              v-for="item in businessTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="businessTypeVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateSourceBusinessType()">确 定</el-button>
      </span>
    </el-dialog>

    <!--编辑accountManager模态框-->
    <el-dialog title="提示" :visible.sync="accountVisible" width="30%">
      <!-- <el-input v-model="accountManage" placeholder="please add here"></el-input> -->
      <el-form :model="sourceAccount">
        <el-form-item label="BD Name">
          <el-select v-model="accountManage" filterable placeholder="Please select" class="w100">
            <el-option
              v-for="item in option.manager"
              :key="item.username"
              :label="item.username"
              :value="item.username"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="accountVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateAccountManager()">确 定</el-button>
      </span>
    </el-dialog>

    <!--编辑accountManager 类型模态框-->
    <el-dialog title="提示" :visible.sync="accountTypeVisible" width="30%">
      <!--      <el-input v-model="businessType" placeholder="please add here"></el-input>-->
      <el-form :model="sourceAccountType">
        <el-form-item label="BD Group">
          <el-select v-model="accountType" placeholder="Please select" class="w100">
            <el-option
              v-for="item in businessTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="accountTypeVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateAccountType()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    getSourceList,
    insertSource,
    updateSource,
    updateBusiness,
    updateBusinessType,
    updateAccountManager,
  } from 'api/source/overall';
  import { myMixin } from '@/mixins/mixins.js';
  import Pagination from '@/components/pagination';
  import {
    getPermissionAllList,
    selectGroupBusiness,
    selectGroupAccount,
  } from 'api/account/permission';
  import { mapState, mapActions } from 'vuex';
  import { cloneDeep } from 'lodash-es';
  import GroupSelect from '@/components/GroupSelect';
  import { getAllTags } from '@/api/affiliate/tag';

  let clickTimer = null;

  const defaultSource = {
    sourceName: '',
    weightiness: '',
    description: '',
    trafficType: '',
    payoutType: 'CPA',
    timeZone: '',
    trafficTag: [],
    clientArea: '',
    email: '',
    emails: [
      {
        value: '',
      },
    ],
  };
  export default {
    name: 'SourceOverall',
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        pageSize: 20,
        currentPage: 1,
        filter: {
          sourceIds: null,
          sourceName: null,
          payoutType: null,
          status: 'enable',
        },
        source: Object.assign({}, defaultSource),
        addWhitelistLimited: [
          {
            label: 'YES',
            value: 'YES',
          },
          {
            label: 'NO',
            value: 'NO',
          },
        ],
        listLoading: false,
        total: null,

        trafficTypes: [
          {
            label: 'Incent',
            value: '20',
          },
          {
            label: 'Network',
            value: '30',
          },
          {
            label: 'In-app',
            value: '40',
          },
          {
            label: 'Direct',
            value: '50',
          },
        ],
        payoutType: [
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
          {
            label: 'CPL',
            value: 'CPL',
          },
        ],
        businessTypes: [
          {
            label: 'overseas',
            value: 'overseas',
          },
          {
            label: 'domestic',
            value: 'domestic',
          },
        ],
        sourceStatus: [
          {
            label: 'enable',
            value: 'enable',
          },
          {
            label: 'disable',
            value: 'disable',
          },
          {
            label: 'pending',
            value: 'pending',
          },
          {
            label: 'reject',
            value: 'reject',
          },
          {
            label: 'prepare',
            value: 'prepare',
          },
        ],
        sourceUpdateStaatus: [
          {
            label: 'ENABLE',
            value: 'ENABLE',
          },
          {
            label: 'Disable',
            value: 'DISABLE',
          },
          {
            label: 'Pending',
            value: 'PENDING',
          },
          {
            label: 'Reject',
            value: 'REJECT',
          },
          {
            label: 'Prepare(稍后开启)',
            value: 'PREPARE',
          },
        ],
        closemoda: false,
        dialogType: 'new',
        dialogVisible: false,
        sourceaddFlag: false,
        businessVisible: false,
        business: '',
        sourceBusiness: {},
        businessTypeVisible: false,
        businessType: '',
        sourceBusinessType: {},
        accountVisible: false,
        accountManage: '',
        sourceAccount: {},
        accountTypeVisible: false,
        accountType: '',
        sourceAccountType: {},
        payoutTypes: [
          {
            label: 'All',
            value: '',
          },
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
          {
            label: 'CPL',
            value: 'CPL',
          },
          {
            label: 'SmartLink',
            value: 'SmartLink',
          },
        ],
        option: {
          manager: [],
        },
        dataList: [],
        tagOption: [],
        busineies: [],
        accounts: [],
        rules: {
          manager: [
            {
              required: true,
              message: '请选择manager',
              trigger: 'change',
            },
          ],
          businessType: [
            {
              required: true,
              message: '请选择BD GROUP',
              trigger: 'change',
            },
          ],
          business: [
            {
              required: true,
              message: '请选择BD NAME',
              trigger: 'blur',
            },
          ],
          accountManage: [
            {
              required: true,
              message: '请选择填写AM',
              trigger: 'blur',
            },
          ],
          accountType: [
            {
              required: true,
              message: '请选择AM GROUP',
              trigger: 'change',
            },
          ],
          clientArea: [
            {
              required: true,
              message: '请选择Client Area',
              trigger: 'change',
            },
          ],
          company: [
            {
              required: true,
              message: '请输入SourceName',
              trigger: 'blur',
            },
          ],
          weightiness: [
            {
              required: true,
              message: '请输入Weightiness',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: '请输入Description',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    computed: {
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      this.searchListClick();
      this.getSourceIdsList();
      this.managerList();
      this.getTagList();
      this.getBusiness();
      this.getAccounts();
    },
    methods: {
      ...mapActions('source', ['getSourceIdsList']),
      // 获取manager列表
      managerList() {
        getPermissionAllList().then((res) => {
          console.log(res);
          this.option.manager = res.result;
        });
      },
      getBusiness() {
        selectGroupBusiness().then((res) => {
          this.busineies = res.result;
        });
      },
      getAccounts() {
        selectGroupAccount().then((res) => {
          this.accounts = res.result;
        });
      },
      searchListClick(curPage) {
        this.listLoading = true;
        if (curPage) {
          this.currentPage = curPage;
        }
        let param = {
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (param.sourceIds && Array.isArray(param.sourceIds)) {
          param.sourceIds = param.sourceIds.join(',');
        }
        if (param.businessList && Array.isArray(param.businessList)) {
          param.businessList = param.businessList.join(',');
        }
        if (param.accountList && Array.isArray(param.accountList)) {
          param.accountManages = param.accountList.join(',');
        }
        getSourceList(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            this.dataList = response.result;
            this.total = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      submitClick(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const params = { ...this.source };
            if (params.trafficTag) {
              params.trafficTag = params.trafficTag?.join(',');
            }
            if (this.dialogType === 'new') {
              var patrn = /^(([1-9](\.\d{2})?)|10|10.0)$/;
              if (!patrn.test(this.source.weightiness)) {
                this.$message.error('Weightiness只限于1到10~');
                return;
              }

              insertSource(params).then((response) => {
                if (response.code === 200) {
                  this.$message({
                    message: 'Add Success',
                    type: 'success',
                  });
                  this.searchListClick();
                  this.dialogVisible = false;
                } else {
                  this.$message.error('Add Error:' + response.message);
                }
              });
            } else {
              updateSource(params).then((response) => {
                if (response.code === 200) {
                  this.$message({
                    message: 'Update Success',
                    type: 'success',
                  });
                  this.searchListClick();
                } else {
                  this.$message.error('Update Error:' + response.message);
                }
              });
              this.dialogVisible = false;
            }
            this.sourceaddFlag = false;
          }
        });
      },
      updateClick(row) {
        if (row.email) {
          row.emails = JSON.parse(row.email);
        } else {
          row.emails = [
            {
              value: '',
            },
          ];
        }
        const source = cloneDeep(row);
        source.sourceName = source.company;
        source.trafficTag = source.trafficTag ? source.trafficTag.split(',') : [];
        this.source = source;
        this.dialogType = 'edit';
        this.dialogVisible = true;
        if (this.$refs.source) {
          this.$refs.source.resetFields();
        }
      },
      addDomain(data) {
        if (data === 'add') {
          this.source.emails.push({
            value: '',
            key: Date.now(),
          });
        } else {
          this.source.emails.push({
            value: '',
            key: Date.now(),
          });
        }
      },
      removeDomain(item) {
        var index = this.source.emails.indexOf(item);
        if (index !== -1) {
          this.source.emails.splice(index, 1);
        }
      },
      createClick() {
        this.source = Object.assign({}, defaultSource);
        this.dialogType = 'new';
        this.dialogVisible = true;
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.searchListClick();
      },
      detailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/source/detail',
              query: {
                sourceId: row.id,
                sourceName: row.company,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      getTagList() {
        getAllTags('offer')
          .then((resp) => {
            if (resp.code === 200) {
              this.tagOption = resp.result?.map((item) => ({
                label: item.trafficTag,
                value: item.trafficTag,
              }));
            } else {
              this.tagOption = [];
            }
          })
          .catch(() => {
            this.tagOption = [];
          });
      },
      updateSourceBusiness() {
        this.sourceBusiness.business = this.business;
        updateBusiness({ ...this.sourceBusiness }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: response.message,
              type: 'success',
            });
            this.searchListClick();
            this.businessVisible = false;
          } else {
            this.$message.error('Update Error:' + response.messsage);
          }
        });
      },
      updateSourceBusinessType() {
        this.sourceBusinessType.businessType = this.businessType;
        updateBusinessType({ ...this.sourceBusinessType }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: response.message,
              type: 'success',
            });
            this.searchListClick();
            this.businessTypeVisible = false;
          } else {
            this.$message.error('Update Error:' + response.messsage);
          }
        });
      },
      updateAccountManager() {
        this.sourceAccount.accountManage = this.accountManage;
        updateAccountManager({ ...this.sourceAccount }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: response.message,
              type: 'success',
            });
            this.searchListClick();
            this.accountVisible = false;
          } else {
            this.$message.error('Update Error:' + response.messsage);
          }
        });
      },
      updateAccountType() {
        this.sourceAccountType.accountType = this.accountType;
        updateAccountManager({ ...this.sourceAccountType }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: response.message,
              type: 'success',
            });
            this.searchListClick();
            this.accountTypeVisible = false;
          } else {
            this.$message.error('Update Error:' + response.messsage);
          }
        });
      },

      handleBusiness(row) {
        this.business = row.business;
        this.businessVisible = true;
        this.sourceBusiness.id = row.id;
      },
      handleBusinessType(row) {
        this.businessType = row.businessType;
        this.businessTypeVisible = true;
        this.sourceBusinessType.id = row.id;
        console.log(this.sourceBusinessType);
      },
      handleAccountManager(row) {
        this.accountManage = row.accountManage;
        this.accountVisible = true;
        this.sourceAccount.id = row.id;
        this.sourceAccount.accountType = row.accountType;
      },
      handleAccountType(row) {
        this.accountType = row.accountType;
        this.accountTypeVisible = true;
        this.sourceAccountType.id = row.id;
        this.sourceAccountType.accountManage = row.accountManage;
      },
      formatEmail(arr) {
        try {
          let list = JSON.parse(arr);
          const values = list.map((obj) => obj.value);
          return values;
        } catch (e) {
          return arr;
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
